module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"cmp-builders","short_name":"{{short_name}}","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"/Users/illmonk/Documents/GitHub/crisp-solution-backend/websites/732d628a-b22e-425c-9e9b-f3873d154a26/src/images/64.png","icons":[{"src":"/Users/illmonk/Documents/GitHub/crisp-solution-backend/websites/732d628a-b22e-425c-9e9b-f3873d154a26/src/images/64.png","sizes":"64x64 32x32 24x24 16x16","type":"image/png"},{"src":"/Users/illmonk/Documents/GitHub/crisp-solution-backend/websites/732d628a-b22e-425c-9e9b-f3873d154a26/src/images/logo192.png","sizes":"192x192","type":"image/png"},{"src":"/Users/illmonk/Documents/GitHub/crisp-solution-backend/websites/732d628a-b22e-425c-9e9b-f3873d154a26/src/images/logo512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"80b3a37f3c0ce73bb33e96c8f366ad16"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-XXXXXXXX"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
